import {
  SupervisionRequestResponseDtoTypeEnum,
  TherapistTherapiesInfoResponseDtoSpecialisationEnum,
  TherapistWorkingInfoResponseDtoLanguagesEnum,
  TherapistWorkingInfoResponseDtoWorkWithReligionsEnum,
} from 'shared/api';

export const VARS = {
  monthNames: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  russianTranslateLanguage: {
    [TherapistWorkingInfoResponseDtoLanguagesEnum.English]: 'Английский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Russian]: 'Русский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Kazakh]: 'Казахский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.German]: 'Немецкий',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Tatar]: 'Татарский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Armenian]: 'Армянский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.French]: 'Французский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Spanish]: 'Испанский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Italian]: 'Итальянский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Chinese]: 'Китайский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Arab]: 'Арабский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Azerbaijani]:
      'Азербайджанский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Georgian]: 'Грузинский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Uzbek]: 'Узбекский',
  },
  russianTranslateReligions: {
    [TherapistWorkingInfoResponseDtoWorkWithReligionsEnum.Christianity]:
      'Христианство',
    [TherapistWorkingInfoResponseDtoWorkWithReligionsEnum.Islamism]: 'Ислам',
    [TherapistWorkingInfoResponseDtoWorkWithReligionsEnum.Judaism]: 'Иудаизм',
    [TherapistWorkingInfoResponseDtoWorkWithReligionsEnum.Buddhism]: 'Буддизм',
  },
  russianTranslateeSpecializations: {
    [TherapistTherapiesInfoResponseDtoSpecialisationEnum.Psychologist]:
      'Психолог',
    [TherapistTherapiesInfoResponseDtoSpecialisationEnum.Psychotherapist]:
      'Психотерапевт',
    [TherapistTherapiesInfoResponseDtoSpecialisationEnum.Psychoanalyst]:
      'Психиатр',
    [TherapistTherapiesInfoResponseDtoSpecialisationEnum.Coach]: 'Коуч',
    [TherapistTherapiesInfoResponseDtoSpecialisationEnum.Supervisor]:
      'Супервизор',
    [TherapistTherapiesInfoResponseDtoSpecialisationEnum.ClinicalPsychologist]:
      'Клинический психолог',
  },
  russianTranslateSupervisionType: {
    [SupervisionRequestResponseDtoTypeEnum.Group]: 'Групповая',
    [SupervisionRequestResponseDtoTypeEnum.Personal]: 'Персональная',
  },
  promocodesChoices: [
    { id: 100, name: '100 руб' },
    { id: 200, name: '200 руб' },
    { id: 300, name: '300 руб' },
    { id: 500, name: '500 руб' },
    { id: 1000, name: '1.000 руб' },
    { id: 2000, name: '2.000 руб' },
    { id: 3000, name: '3.000 руб' },
    { id: 4000, name: '4.000 руб' },
    { id: 5000, name: '5.000 руб' },
    { id: 6000, name: '6.000 руб' },
    { id: 7000, name: '7.000 руб' },
    { id: 8000, name: '8.000 руб' },
    { id: 9000, name: '9.000 руб' },
    { id: 10000, name: '10.000 руб' },
  ],
  payoutStatusesChoices: [
    { id: 1, name: 'awaiting' },
    { id: 2, name: 'pending' },
    { id: 3, name: 'awaiting_promocode' },
    { id: 4, name: 'succeeded' },
    { id: 5, name: 'canceled' },
  ],
};
