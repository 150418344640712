export const LABELS = {
  utmCampaign: 'utm_campaign',
  utmMedium: 'utm_medium',
  utmSource: 'utm_source',
  firstname: 'Имя',
  lastname: 'Фамилия',
  rewardInPercent: '% выплаты',
  isTop: 'ТОП-терапевт',
  isTestTherapist: 'Тестовый',
  isTestPatient: 'Тестовый пациент',
  isMedsi: 'От MEDSI',
  isChildTherapist: 'Детский',
  isCardAssigned: 'Прикреплена карта',
  dateOfBirth: 'Дата рождения',
  gender: 'Пол',
  email: 'Почта',
  phone: 'Телефон',
  avatarKey: 'Фотография',
  country: 'Страна',
  city: 'Город',
  password: 'Пароль',
  name: 'Название',
  companyName: 'Имя компании',
  isEmailConfirmed: 'Почта подтверждена',
  languages: 'Языки',
  devoteHoursToPlatform: 'Готов тратить часов на платформу',
  numberOfPatients: 'Количество пациентов в работе',
  workWithCouples: 'Работает с парами',
  workWithFamilies: 'Работает с семьями',
  workWithGroups: 'Работает с группами',
  workWithChildren: 'Работает с детьми',
  workWithLgbt: 'Работает с ЛГБТ',
  workWithReligions: 'Работает с религиозными направленностями',
  workingExperience: 'Стаж работы',
  workingExperienceDescription: 'Описание опыта работы',
  ethnicExperienceDescription: 'Описание опыта работы с этническими группами',
  university: 'Университет',
  speciality: 'Специальность',
  files: 'Файлы',
  organisation: 'Организация',
  course: 'Курс',
  isSupervisor: 'Является супервизором',
  supervisionExperience: 'Стаж работы супервизором',
  recommendationText: 'Кто рекомендует',
  recommendationFiles: 'Файлы рекомендации',
  supervisionFrequency: 'Как часто посещает супервизии',
  supervisionType: 'Тип супервизии',
  supervisionVisitsDuration: 'Сколько посещает супервизии',
  supervisionExperienceDescription: 'Описание опыта супервизии',
  specialisation: 'Специализация',
  therapistProfileDiseases: 'Работаю с',
  therapistProfileTherapyMethods: 'Методы терапии',
  about: 'О себе',
  diseasesGroup: 'Группа заболеваний',
  description: 'Описание',
  title: 'Заголовок',
  question: 'Вопрос',
  answer: 'Ответ',
  promocodeAmount: 'Номинал',
  promocodePercentage: 'Процент',
  promocodeFirstUseOnly: 'Только первое использование',
  promocodeQuantity: 'Количество',
  promocodeExpireDate: 'Истечение срока действия',
  price: 'Стоимость консультации',
  contactByPhone: 'Связь по телефону',
  medsiSessionsClients: 'Медси',
  medsiPogovorim: 'Pogovorim'
};
